<ag-grid-angular
  [gridOptions]="internalGridOptions"
  class="ag-theme-dewert-okin flex-grow-1"
  [columnDefs]="columnDefs"
  [rowData]="items"
  [modules]="modules"
  [rowSelection]="rowSelection"
  (selectionChanged)="onSelectionChanged($event)"
  (rowDataChanged)="rowDataChanged()"
  (rowClicked)="rowClickedChanged($event)"
  (rowDoubleClicked)="rowDoubleClickedChanged($event)"
  (gridReady)="onGridReady($event)"
>
</ag-grid-angular>
