import { isEqual } from 'lodash';
import { debounceTime, Subject, Subscription } from 'rxjs';

import { AgFrameworkComponent } from '@ag-grid-community/angular';
import { IFloatingFilter } from '@ag-grid-community/core';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
  AgGridFilter,
  buildAgGridBooleanFilter,
  buildAgGridDateFilter,
  buildAgGridFilter,
  CustomGridFilterParams,
} from '@do/app-common';
import { FilterOperator, FilterType } from '@do/common-interfaces';

import { SingleFilterComponent } from '../filters/single-filter/single-filter.component';

@Component({
  selector: 'do-custom-grid-filter',
  template: `
    <do-single-filter
      *ngIf="!params.hidden"
      [type]="params.filterType"
      [showLabel]="false"
      [items]="params.values"
      [field]="params.field"
      [readOnly]="params.readOnly"
      [entityName]="params.entityName"
      [ngModel]="currentValue"
      (ngModelChange)="valueChanged($event)"
    ></do-single-filter>
  `,
  styles: [
    `
      :host {
        width: 100%;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, SingleFilterComponent, FormsModule],
})
export class CustomGridFilterComponent
  implements
    IFloatingFilter,
    AgFrameworkComponent<CustomGridFilterParams>,
    OnDestroy
{
  public params!: CustomGridFilterParams;
  public currentValue!: {
    operator: FilterOperator;
    value: any[];
  } | null;
  public subscription!: Subscription;

  valueChangedSubject = new Subject<any>();

  filterTypes = FilterType;

  @ViewChild(SingleFilterComponent)
  comp?: SingleFilterComponent;

  constructor(private cd: ChangeDetectorRef) {}

  agInit(params: CustomGridFilterParams): void {
    this.params = params;

    this.currentValue = null;

    this.subscription = this.valueChangedSubject
      .pipe(debounceTime(1000))
      .subscribe(() => {
        if (this.currentValue) {
          if (
            (this.params.filterType === FilterType.date ||
              this.params.filterType === FilterType.datetime) &&
            this.currentValue.operator === FilterOperator.inRange &&
            this.currentValue.value.some((v) => !v)
          ) {
            return;
          }

          let filterModel: AgGridFilter;

          if (
            this.params.filterType === FilterType.date ||
            this.params.filterType === FilterType.datetime
          ) {
            filterModel = buildAgGridDateFilter({
              ...this.currentValue,
              type: FilterType.date,
            });
          } else if (this.params.filterType === FilterType.boolean) {
            if (this.params.column.getColDef().valueGetter) {
              filterModel = buildAgGridFilter({
                ...this.currentValue,
                type: FilterType.text,
              });
            } else {
              filterModel = buildAgGridBooleanFilter({
                ...this.currentValue,
                type: FilterType.boolean,
              });
            }
          } else {
            filterModel = buildAgGridFilter({
              ...this.currentValue,
              type: this.params.filterType,
            });
          }

          const actualModel = this.params.api
            .getFilterInstance(this.params.column)
            ?.getModel();
          if (!isEqual(filterModel, actualModel)) {
            this.params.api
              .getFilterInstance(this.params.column)
              ?.setModel(filterModel);
          }
        } else {
          this.params.api.getFilterInstance(this.params.column)?.setModel(null);
        }
        this.params.api.onFilterChanged();
      });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  valueChanged(value: any) {
    // console.log(
    //   '[UP] CUSTOM FILTER <- SINGLE FILTER, received value from inner filter: ',
    //   value
    // );
    this.currentValue = value;
    this.valueChangedSubject.next(this.currentValue);
  }

  onParentModelChanged(parentModel: any): void {
    // console.log('parent changed', parentModel);

    // console.log(
    //   '[DOWN] GRID MODEL -> CUSTOM FILTER , received value from parent grid model',
    //   parentModel
    // );
    if (!parentModel) {
      this.currentValue = null;
    } else {
      const op = parentModel.type as string;
      const newValue = {
        operator: (<any>FilterOperator)[op],
        value: [],
      } as { value: any[]; operator: FilterOperator };

      if (parentModel.filter) {
        newValue.value.push(parentModel.filter);
      }
      if (parentModel.filterTo) {
        newValue.value.push(parentModel.filterTo);
      }
      if (parentModel.dateFrom) {
        newValue.value.push(parentModel.dateFrom);
      }
      if (parentModel.dateTo) {
        newValue.value.push(parentModel.dateTo);
      }
      this.currentValue = newValue;
    }

    this.cd.detectChanges();
  }
}
