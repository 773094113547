<ng-container [formGroup]="valueForm">
  <div class="d-flex align-items-center position-relative">
    <label class="text-light" *ngIf="showLabel" [title]="label">{{
      label
    }}</label>

    <do-operators
      [items]="operators"
      [class.ms-auto]="showLabel"
      [right]="showLabel"
      name="operator"
      formControlName="operator"
      [readOnly]="readOnly"
    ></do-operators>

    <span
      tabindex="0"
      class="material-symbols-outlined ms-1 text-danger cursor-pointer cancel-icon"
      *ngIf="showClear()"
      (click)="clear(true)"
    >
      close
    </span>
  </div>
  <div
    class="d-flex align-items-center"
    formArrayName="value"
    [class.dirty]="isDirty()"
  >
    <ng-container
      *ngFor="let valueControl of valueArray.controls; let i = index"
    >
      <span *ngIf="i === 1">&nbsp;</span>
      <do-text-input
        *ngIf="type === filterTypes.text"
        class="flex-grow-1"
        name="value"
        [readOnly]="readOnly || false"
        [formControl]="$any(valueControl)"
      >
      </do-text-input>

      <do-numeric-input
        *ngIf="type === filterTypes.number"
        class="flex-grow-1"
        name="value"
        [readOnly]="readOnly || false"
        [formControl]="$any(valueControl)"
      >
      </do-numeric-input>

      <do-datepicker
        *ngIf="type === filterTypes.date || type === filterTypes.datetime"
        class="flex-grow-1"
        name="value"
        [showIcon]="false"
        [readOnly]="readOnly || false"
        [formControl]="$any(valueControl)"
      >
      </do-datepicker>

      <do-select
        *ngIf="type === filterTypes.enum || type === filterTypes.boolean"
        class="flex-grow-1"
        name="value"
        [items]="items"
        [clearable]="false"
        [bindValue]="'value'"
        [bindLabel]="'description'"
        [formControl]="$any(valueControl)"
        [appendTo]="'body'"
        [readOnly]="readOnly || false"
      >
      </do-select>

      <div
        class="position-relative decode"
        *ngIf="type === filterTypes.autocomplete"
      >
        <input
          #decodeInput
          type="text"
          class="form-control"
          [formControl]="$any(valueControl)"
          [ngbTypeahead]="search"
          [showHint]="true"
          [editable]="false"
          [container]="'body'"
          [readOnly]="readOnly || false"
          (selectItem)="selected($event, $any(valueControl))"
          (blur)="blur(decodeInput, $any(valueControl))"
        />
        <div
          *ngIf="autocompleteLoading"
          class="spinner-border spinner-border-sm text-primary"
          role="status"
        >
          <span class="visually-hidden" translate>Loading...</span>
        </div>
      </div>
    </ng-container>
  </div>

  <!-- <strong>
    {{ valueForm.value | json }}
  </strong> -->
</ng-container>
