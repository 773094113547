import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { FilterOperator } from '@do/common-interfaces';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { OperatorItem } from './operators';

@Component({
  selector: 'do-operators',
  templateUrl: './operators.component.html',
  styleUrls: ['./operators.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, NgSelectModule, FormsModule, TranslateModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OperatorFilterComponent),
      multi: true,
    },
  ],
})
export class OperatorFilterComponent implements ControlValueAccessor {
  private _value!: FilterOperator;
  get value() {
    return this._value;
  }

  // @Input()
  set value(value: FilterOperator) {
    this._value = value;
    // if (value !== this.operatorControl.value) {
    //   this.operatorControl.setValue(value);
    // }
    this.selectedItem = this.items.find((o) => o.value === value);
  }

  @Input()
  items: OperatorItem[] = [];

  @Input()
  readOnly?: boolean = false;

  selectedItem?: OperatorItem;

  @Input()
  right = false;

  @Output()
  valueChanged = new EventEmitter<FilterOperator>();

  constructor(
    private cd: ChangeDetectorRef,
    private translateService: TranslateService
  ) {}

  propagateChange!: (value: FilterOperator) => void;
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched = () => {};

  writeValue(obj: FilterOperator | undefined): void {
    if (obj != null) this.value = obj;
    this.cd.detectChanges();
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setOperator(item: OperatorItem) {
    this.selectedItem = item;

    this.valueChanged.emit(item.value);

    this.propagateChange(item.value);
  }
}
