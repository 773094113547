<div class="input-group">
  <input
    [ngStyle]="{ visibility: hide ? 'hidden' : null }"
    #dp="ngbDatepicker"
    #input
    type="text"
    [class]="inputClasses"
    [class.is-invalid]="hasError"
    name="dp"
    ngbDatepicker
    [(ngModel)]="innerValue"
    (ngModelChange)="onModelChange()"
    [disabled]="isDisabled"
    [container]="$any(container)"
    [autoClose]="autoClose"
    [minDate]="$any(internalMinDate)"
    [maxDate]="$any(internalMaxDate)"
    [readOnly]="readOnly"
  />
  <div class="input-group-append" *ngIf="showIcon && !readOnly">
    <div class="input-group-text cursor-pointer" (click)="onToggle()">
      <span class="material-symbols-outlined"> calendar_today </span>
    </div>
  </div>
</div>
