/* eslint-disable @typescript-eslint/no-unused-vars */
import { merge } from 'lodash';
import moment from 'moment';

import { AgGridAngular } from '@ag-grid-community/angular';
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import {
  ColDef,
  ColumnApi,
  ColumnMovedEvent,
  ColumnResizedEvent,
  ColumnState,
  ColumnVisibleEvent,
  GetRowIdParams,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ICellRendererParams,
  IRowNode,
  RowClickedEvent,
  RowDoubleClickedEvent,
  SortChangedEvent,
  SuppressKeyboardEventParams,
  ValueFormatterParams,
  ValueGetterParams,
} from '@ag-grid-community/core';
import { CurrencyPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  input,
  OnInit,
  Output,
} from '@angular/core';
import {
  DataGridAction,
  getAllFocusableElementsOf,
  getEventPath,
} from '@do/app-common';
import { Precision, SelectItem } from '@do/common-interfaces';
import { decimalFormatter } from '@do/common-utils';
import { TranslateService } from '@ngx-translate/core';

import { CustomGridFilterComponent } from '../custom-grid-filter.component';
import { DataGridActionParamsDataInterface } from './data-grid-action-params-data-interface';
import { DataGridActionRenderer } from './data-grid-action-renderer.component';

let nextId = 0;

@Component({
  selector: 'do-data-grid',
  templateUrl: './data-grid.component.html',
  styleUrls: ['./data-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CurrencyPipe],
  standalone: true,
  imports: [AgGridAngular],
})
export class DataGridComponent implements OnInit {
  id = nextId++;

  canEdit = input(false);

  canDelete = input(false);

  canView = input(false);

  @Input()
  gridOptions?: GridOptions;

  @Input()
  hideCommands = false;

  @Input()
  getRowId = (param: GetRowIdParams) => param.data.id;

  @Input()
  rowSelection?: 'single' | 'multiple';

  // private _selectedRow = undefined;
  // get selectedRow() {
  //   return this._selectedRow;
  // }
  // @Input()
  // set selectedRow(value: any) {
  //   this._selectedRow = value;

  //   this.gridApi?.getRowNode(value.id)?.setSelected(true);
  // }

  private _canSelect = false;
  get canSelect() {
    return this._canSelect;
  }
  @Input()
  set canSelect(value: boolean) {
    this._canSelect = value;
    this.rowSelection = value ? 'multiple' : 'single';
  }

  private _selectedItems: any[] = [];
  get selectedItems() {
    return this._selectedItems;
  }
  @Input()
  set selectedItems(values: any[]) {
    this._selectedItems = values;
    this.setSelectedItems();
  }

  internalGridOptions!: GridOptions;

  private _columnDefs?: ColDef[];
  get columnDefs(): ColDef[] | undefined {
    return this._columnDefs;
  }
  @Input() set columnDefs(value: ColDef[]) {
    this._columnDefs = [...value].map((cd) => ({
      ...cd,
      headerValueGetter: (params) => {
        return this.translate.instant(params.colDef.headerName || '');
      },
    }));

    if (this._columnDefs) {
      if (this.canSelect) {
        this.columnDefs?.splice(0, 0, {
          colId: 'selection',
          headerCheckboxSelection: true,
          checkboxSelection: true,
          lockPosition: 'left',
          width: 30,
          filter: false,
          sortable: false,
          resizable: false,
          minWidth: 0,
          suppressMovable: true,
          suppressColumnsToolPanel: true,
          flex: 0,
        });
      }

      if (!this.hideCommands) {
        const actionsIndex = this._columnDefs.findIndex(
          (c) => c.colId === 'actions'
        );
        if (actionsIndex >= 0) this._columnDefs.splice(actionsIndex, 1);

        this._columnDefs.push({
          colId: 'actions',
          // lockPosition: 'right',
          pinned: 'right',
          width: 80,
          type: 'actionColumn',
          cellRendererParams: {
            context: {
              actions: () => {
                return this.getActions();
              },
            },
          },
          filter: false,
          sortable: false,
          resizable: false,
          minWidth: 0,
          suppressMovable: true,
          suppressColumnsToolPanel: true,
          flex: 0,
          suppressKeyboardEvent: ({
            event,
            api,
          }: SuppressKeyboardEventParams<any>) => {
            const { key, shiftKey } = event;
            const path = getEventPath(event);
            const isTabForward = key === 'Tab' && shiftKey === false;
            const isTabBackward = key === 'Tab' && shiftKey === true;
            let suppressEvent = false;
            // Handle cell children tabbing
            if (isTabForward || isTabBackward) {
              const eGridCell = path.find((el) => {
                if (el.classList === undefined) return false;
                return el.classList.contains('ag-cell');
              });
              if (!eGridCell) {
                return suppressEvent;
              }
              const focusableChildrenElements =
                getAllFocusableElementsOf(eGridCell);
              const lastCellChildEl =
                focusableChildrenElements[focusableChildrenElements.length - 1];
              const firstCellChildEl = focusableChildrenElements[0];
              // Suppress keyboard event if tabbing forward within the cell and the current focused element is not the last child
              if (isTabForward && focusableChildrenElements.length > 0) {
                const isLastChildFocused =
                  lastCellChildEl && document.activeElement === lastCellChildEl;
                if (!isLastChildFocused) {
                  suppressEvent = true;
                }
              }
              // Suppress keyboard event if tabbing backwards within the cell, and the current focused element is not the first child
              else if (isTabBackward && focusableChildrenElements.length > 0) {
                const cellHasFocusedChildren =
                  eGridCell.contains(document.activeElement) &&
                  eGridCell !== document.activeElement;
                // Manually set focus to the last child element if cell doesn't have focused children
                if (!cellHasFocusedChildren) {
                  lastCellChildEl.focus();
                  // Cancel keyboard press, so that it doesn't focus on the last child and then pass through the keyboard press to
                  // move to the 2nd last child element
                  event.preventDefault();
                }
                const isFirstChildFocused =
                  firstCellChildEl &&
                  document.activeElement === firstCellChildEl;
                if (!isFirstChildFocused) {
                  suppressEvent = true;
                }
              }
            }
            return suppressEvent;
          },
        } as ColDef);
      }
      this.gridApi?.setColumnDefs(this._columnDefs);
    }
  }

  // @Input()
  // items: any[] = [];
  private _items: any[] = [];
  get items() {
    return this._items;
  }
  @Input()
  set items(values: any[]) {
    this._items = values;
    this.setSelectedItems();
  }

  @Input()
  customActions?: DataGridAction[] = [];

  @Output()
  download: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  edit = new EventEmitter<any>();

  @Output()
  view = new EventEmitter<any>();

  @Output()
  delete = new EventEmitter<any>();

  @Output()
  selected = new EventEmitter<any[]>();

  @Output()
  columnStateChanged = new EventEmitter<ColumnState[]>();

  @Output()
  rowClicked = new EventEmitter<any>();

  @Output()
  rowDoubleClicked = new EventEmitter<any>();

  modules = [
    // RowGroupingModule,
    ClientSideRowModelModule,
    // ColumnsToolPanelModule,
    // ServerSideRowModelModule,
    // ClipboardModule,
  ];

  gridApi?: GridApi;
  columnApi?: ColumnApi;

  constructor(
    private currencyPipe: CurrencyPipe,
    private cd: ChangeDetectorRef,
    private translate: TranslateService
  ) {}

  getPrecisionParams = (precision?: Precision): Precision => {
    const defaultParams = {
      minimumFractionDigits: 2,
      maximumFractionDigits: 8,
    };
    const {
      minimumFractionDigits = defaultParams.minimumFractionDigits,
      maximumFractionDigits = defaultParams.maximumFractionDigits,
    } = precision || {};
    return { minimumFractionDigits, maximumFractionDigits };
  };

  ngOnInit(): void {
    const commonGridOptions: GridOptions = {
      overlayNoRowsTemplate: '<span>No results</span>',
      rowHeight: 48,

      floatingFiltersHeight: 90,
      headerHeight: 48,
      suppressCellFocus: false,
      // enableCellTextSelection: true,
      ensureDomOrder: true,
      rowSelection: this.rowSelection,
      // sideBar: {
      //   toolPanels: [
      //     {
      //       id: 'columns',
      //       labelDefault: 'Colonne',
      //       labelKey: 'columns',
      //       iconKey: 'columns',
      //       toolPanel: 'agColumnsToolPanel',
      //       toolPanelParams: {
      //         suppressPivotMode: true,
      //         suppressRowGroups: true,
      //         suppressValues: true,
      //       },
      //     },
      //   ],
      // },
      onGridReady: (e: GridReadyEvent) => this.onGridReady(e),
      onSortChanged: (e: SortChangedEvent) => this.onColumnStateChanged(e),
      onColumnVisible: (e: ColumnVisibleEvent) => this.onColumnStateChanged(e),
      onColumnMoved: (e: ColumnMovedEvent) => this.onColumnStateChanged(e),
      onColumnResized: (e: ColumnResizedEvent) => this.onColumnStateChanged(e),
      defaultColDef: {
        editable: false,
        enablePivot: false,
        enableValue: false,
        sortable: true,
        resizable: true,
        flex: 1,
        // minWidth: 200,
        suppressMenu: true,
      },
      components: {
        gridComponentBooleanRenderer: this.gridComponentBooleanRenderer,
        gridComponentEnumRenderer: this.gridComponentEnumRenderer,
        customGridActionRenderer: DataGridActionRenderer,
        customGridFilter: CustomGridFilterComponent,
      },
      columnTypes: {
        boolColumn: {
          cellRenderer: 'gridComponentBooleanRenderer',
        },
        numberColumn: {
          filter: 'agNumberColumnFilter',
        },
        // togliere € e allineare a dx
        currencyColumn: {
          filter: 'agNumberColumnFilter',
          valueFormatter: (params: ValueFormatterParams) => {
            if (params.value == null) {
              return '';
            }
            return this.currencyPipe.transform(params.value + '', ' ') || '';
            // return (
            //   '€ ' + this.currencyPipe.transform(params.value + '', ' ') || ''
            // );
          },
        },
        percentColumn: {
          filter: 'agNumberColumnFilter',
          valueFormatter: (params: ValueFormatterParams<any, number>) => {
            // undefined or null
            if (params.value === undefined || params.value === null) return '';

            // Not a number
            if (typeof params.value !== 'number') return '';

            const precision: Precision | undefined =
              params.column.getUserProvidedColDef()?.cellRendererParams
                ?.precision;

            const value = decimalFormatter(
              this.getPrecisionParams(precision)
            ).format(params.value);

            return value;
          },
        },
        enumColumn: {
          cellRenderer: 'gridComponentEnumRenderer',
        },
        dateColumn: {
          filter: 'agDateColumnFilter',
          // filter: 'agDateColumnFilter',
          valueFormatter: (params: ValueFormatterParams) => {
            if (!params.value) {
              return '';
            }
            return moment(params.value).format('L');
          },
          valueGetter: (params: ValueGetterParams) => {
            const field = params.colDef.field as string;
            const data = field
              .split('.')
              .reduce((p, c) => (p && p[c]) || null, params.data);
            if (data) {
              return new Date(data);
            } else {
              return null;
            }
          },
          tooltipValueGetter: (params) => {
            if (!params.value) {
              return '';
            }
            return moment(params.value).format('L');
          },
        },
        datetimeColumn: {
          filter: 'agDateColumnFilter',
          valueFormatter: (params: ValueFormatterParams) => {
            if (!params.value) {
              return '';
            }
            return moment(params.value).format('DD/MM/YYYY HH:mm');
          },
          valueGetter: (params: ValueGetterParams) => {
            const field = params.colDef.field as string;
            const data = field
              .split('.')
              .reduce((p, c) => (p && p[c]) || null, params.data);
            if (data) {
              return moment(data).toDate();
            } else {
              return null;
            }
          },
          tooltipValueGetter: (params) => {
            if (!params.value) {
              return '';
            }
            return moment(params.value).format('LLL');
          },
        },
        actionColumn: {
          cellRenderer: 'customGridActionRenderer',
        },
      },
      getRowId: this.getRowId,
    };

    if (this.gridOptions) {
      this.internalGridOptions = merge(commonGridOptions, {
        ...this.gridOptions,
        onGridReady: (e: GridReadyEvent) => {
          this.onGridReady(e);
          if (this.gridOptions?.onGridReady) {
            this.gridOptions.onGridReady(e);
          }
        },
      });
    } else {
      this.internalGridOptions = commonGridOptions;
    }
  }

  onGridReady(event: GridReadyEvent) {
    //this.gridApi = event.api;
    // console.log('onGridReady');
    // if (this.actions.length > 0) {
    //   event.api.autoSizeColumn('actions');
    // }

    this.gridApi = event.api;
    // console.log(`**this.gridApi: ${this.gridApi}`);
    this.setSelectedItems();
  }

  gridComponentBooleanRenderer(params: ICellRendererParams) {
    const value = params.value;
    let template = `<div class="d-flex align-items-center h-100">`;
    if (value) {
      template += `<i class="material-symbols-outlined">done</i>`;
      //
    } else {
      template += ``;
      // template += `<i class="material-icons" data-action-type="close">close</i>`;
    }
    return template + '</div>';
  }

  gridComponentEnumRenderer(
    params: ICellRendererParams & { elementValues: SelectItem[] }
  ) {
    const value = params.value;

    let template = `<div class="d-flex align-items-center h-100">`;
    if (value != null) {
      if (
        params.elementValues != undefined &&
        params.elementValues.length > 0
      ) {
        template += params.elementValues.find(
          (e) => e.value === value
        )?.description;
      } else {
        template += value;
      }
      // <i class="material-icons" data-action-type="check">check</i>
    }
    return template + '</div>';
  }

  protected getActions() {
    const actions: DataGridAction[] = [];

    actions.push({
      description: this.translate.instant('edit'),
      icon: 'navigate_next',
      class: 'text-primary',
      //class: 'mdi mdi-pencil',
      onAction: (entity: any) => {
        this.edit.emit(entity);
      },
      visible: (entity: DataGridActionParamsDataInterface) => {
        const isLocked = entity.locked ?? false;
        const canEdit = this.canEdit() ?? false;
        const isVisible = !isLocked && canEdit;
        return isVisible;
      },
    });
    actions.push({
      description: this.translate.instant('view'),
      icon: 'visibility',
      class: 'text-primary',
      onAction: (entity: any) => {
        this.view.emit(entity);
      },
      visible: (entity: DataGridActionParamsDataInterface) => {
        const isLocked = entity.locked ?? false;
        const canView = this.canView ?? true;
        const canEdit = this.canEdit() ?? false;
        const isVisible = !isLocked && canView() && !canEdit;
        return isVisible;
      },
    });
    actions.push({
      description: this.translate.instant('delete'),
      icon: 'delete',
      class: 'text-danger',
      onAction: (entity: any) => {
        this.delete.emit(entity);
      },
      visible: (entity: DataGridActionParamsDataInterface) => {
        const isLocked = entity.locked ?? false;
        const canDelete = this.canDelete() ?? false;
        const isVisible = !isLocked && canDelete;

        return isVisible;
      },
    });

    return [...actions, ...(this.customActions ?? [])];
  }

  onSelectionChanged(event: any) {
    //SelectionChangedEvent
    const selectedRow = this.gridApi?.getSelectedNodes() || [];
    if (this.selected) {
      this.selected.emit(selectedRow.map((r: IRowNode) => r.data));
    }
  }

  setSelectedItems() {
    this.gridApi?.forEachNode((rn) => {
      const selected = !!this._selectedItems.find((v) => v.id === rn.data.id);
      rn.setSelected(selected);
    });
  }

  rowDataChanged() {
    this.setSelectedItems();
  }

  onColumnStateChanged(
    e:
      | SortChangedEvent
      | ColumnVisibleEvent
      | ColumnMovedEvent
      | ColumnResizedEvent
  ) {
    this.columnStateChanged.emit(e.api.getColumnState());
  }

  rowClickedChanged(event: RowClickedEvent) {
    if (this.rowClicked) {
      this.rowClicked.emit(event.data);
    }
  }

  rowDoubleClickedChanged(event: RowDoubleClickedEvent) {
    if (this.rowDoubleClicked) {
      // this.view.emit(event.data);
      this.rowDoubleClicked.emit(event.data);
    }
  }
}
