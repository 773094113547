<ng-select
  *ngIf="!readOnly"
  class="custom-operators-select"
  [class.right]="right"
  [items]="items"
  [bindLabel]="'description'"
  [ngModel]="selectedItem"
  [appendTo]="'body'"
  [clearable]="false"
  [searchable]="false"
  (ngModelChange)="setOperator($event)"
>
  <ng-template ng-option-tmp let-item="item">
    <div class="small-option">{{ item.description }}</div>
  </ng-template>
</ng-select>

<div class="text-primary selected" *ngIf="readOnly">
  {{ selectedItem?.description }}
</div>
