import { AgFrameworkComponent } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DataGridAction } from '@do/app-common';

import { IconButtonComponent } from '../../core/icon-button/icon-button.component';
import { DataGridActionRendererParams } from './data-grid-action-renderer-params';

// export interface CustomGridAction {
//   description: string;
//   class: string;
//   onAction: (entity: any) => void;
// }
// export interface CustomGridActionRendererParams extends ICellRendererParams {
//   actions: CustomGridAction[];
// }

@Component({
  template: `
    <div class="d-flex h-100 align-items-center grid-action-container">
      <do-icon-button
        *ngFor="let action of actions"
        [icon]="action.icon"
        (clicked)="action.onAction(params.data)"
        [title]="action.description"
        [cssClasses]="action.class"
      >
      </do-icon-button>
    </div>
  `,
  styles: [``],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [IconButtonComponent, CommonModule],
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class DataGridActionRenderer
  implements AgFrameworkComponent<DataGridActionRendererParams>
{
  public params!: DataGridActionRendererParams;

  public actions: DataGridAction[] = [];

  agInit(params: DataGridActionRendererParams): void {
    this.params = params;

    this.setActions();

    const actual = this.params.api.getColumn('actions')?.getActualWidth();

    const newWidth = this.actions.length * 28 + 10;

    if (!actual || newWidth > actual) {
      this.params.api.setColumnWidth('actions', this.actions.length * 28 + 10);
    }
  }

  // gets called whenever the cell refreshes
  refresh(params: ICellRendererParams) {
    // set value into cell again
    this.params = params;
    this.setActions();
  }

  private setActions() {
    this.actions = this.params.context
      .actions()
      .filter((a) => a.visible(this.params.data));
  }
}
