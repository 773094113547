/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  Observable,
  of,
  OperatorFunction,
  Subscription,
  switchMap,
  tap,
} from 'rxjs';

import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormArray,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { ApiClientInterface, getDefaultOperator } from '@do/app-common';
import { BaseDto } from '@do/common-dto';
import { FilterOperator, FilterType, SelectItem } from '@do/common-interfaces';
import {
  NgbTypeahead,
  NgbTypeaheadSelectItemEvent,
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

import { DatepickerComponent } from '../../core/datepicker/datepicker.component';
import { NumericInputComponent } from '../../core/numeric-input/numeric-input.component';
import { SelectComponent } from '../../core/select/select.component';
import { TextInputComponent } from '../../core/text-input/text-input.component';
import { OperatorItem, OPERATORS } from '../operators/operators';
import { OperatorFilterComponent } from '../operators/operators.component';

// interface FilterFormValue {
//   operator: FilterOperator;
//   value: any;
//   value2: any;
// }

@Component({
  selector: 'do-single-filter',
  templateUrl: './single-filter.component.html',
  styleUrls: ['./single-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    OperatorFilterComponent,
    ReactiveFormsModule,
    TextInputComponent,
    NumericInputComponent,
    DatepickerComponent,
    SelectComponent,
    NgbTypeahead,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SingleFilterComponent),
      multi: true,
    },
  ],
})
export class SingleFilterComponent
  implements OnInit, OnDestroy, ControlValueAccessor
{
  @Input()
  label!: string;

  @Input()
  type!: FilterType;

  @Input()
  items: SelectItem[] = [];

  @Input()
  field!: string;

  @Input()
  entityName?: string;

  @Input()
  showLabel = true;

  @Input()
  readOnly?: boolean = false;

  autocompleteLoading?: boolean;

  get valueArray() {
    return this.valueForm.controls['value'] as FormArray;
  }

  get operatorControl() {
    return this.valueForm.controls['operator'] as FormControl;
  }

  get valueToEmit() {
    if (!this.isNotEmpty()) {
      return null;
    } else {
      return {
        ...this.valueForm.value,
        value: this.valueForm.value.value ? this.valueForm.value.value : [],
      };
    }
  }

  get decodeField() {
    const splittedField = this.field.split('.').filter((s) => !!s);
    return splittedField[splittedField.length - 1];
  }

  @Output()
  valueChange = new EventEmitter<{
    operator: FilterOperator;
    value: any[];
  } | null>();

  subscriptions: Subscription[] = [];

  valueForm!: FormGroup;

  operators!: OperatorItem[];

  filterTypes = FilterType;

  constructor(
    private cd: ChangeDetectorRef,
    private injector: Injector,
    private translateService: TranslateService
  ) {}

  propagateChange?: (value: { value: any[]; operator: FilterOperator }) => void;
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched = () => {};

  writeValue(obj: { value: any[]; operator: FilterOperator } | null): void {
    // console.log(
    //   '[DOWN] CUSTOM FILTER -> SINGLE FILTER, received value from parent custom filter',
    //   obj
    // );
    if (obj) {
      this.valueForm.patchValue(obj, { emitEvent: false });
      this.onSelectOperator(obj.operator);
      this.valueForm.markAsDirty();
    } else {
      this.clear(false);
    }

    this.cd.detectChanges();
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  ngOnInit(): void {
    if (this.type == null) {
      throw new Error('Filter type mandatory');
    }

    this.operators = OPERATORS.filter(
      (o) => o.filters.filter((f) => f === this.type).length > 0
    ).map((i) => ({
      ...i,
      description: this.translateService.instant(i.description),
    }));
    if (this.type === this.filterTypes.boolean) {
      this.items = [
        { value: 'true', description: this.translateService.instant('Yes') },
        { value: 'false', description: this.translateService.instant('No') },
      ];
    } else if (this.type === this.filterTypes.enum) {
      this.items = this.items.map((item) => ({
        ...item,
        value: item.value + '',
      }));
    }

    this.valueForm = new FormGroup({
      value: new FormArray([new FormControl()]),
      // value2: new FormControl(),
      operator: new FormControl(getDefaultOperator(this.type)),
    });

    this.subscriptions.push(
      this.operatorControl.valueChanges.subscribe((op) => {
        this.onSelectOperator(op);
      })
    );

    this.subscriptions.push(
      this.valueForm.valueChanges.subscribe(() => {
        // console.log(
        //   '[UP] SINGLE FILTER <- CORE FILTER, received value from inner component',
        //   v,
        //   this.valueToEmit
        // );
        if (this.propagateChange) this.propagateChange(this.valueToEmit);
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  onSelectOperator(operator: FilterOperator) {
    const arr = this.valueArray;

    if (operator === FilterOperator.inRange) {
      if (arr.length === 1) {
        arr.push(new FormControl());
      }
    } else if (arr.length === 2) {
      arr.removeAt(1);
    }

    if (
      operator === FilterOperator.blank ||
      operator === FilterOperator.notBlank
    ) {
      this.valueArray.disable({ emitEvent: false });
    } else if (this.valueArray.disabled) {
      this.valueArray.enable({ emitEvent: false });
    }

    this.cd.detectChanges();
  }

  clear(emitEvent: boolean) {
    this.valueForm.patchValue(
      {
        value: [null],
        operator: getDefaultOperator(this.type),
      },
      {
        emitEvent,
      }
    );

    this.valueForm.markAsPristine();
    this.cd.detectChanges();
  }

  isNotEmpty() {
    const isNotEmpty =
      this.valueArray.getRawValue().some((v) => v !== '' && v != null) ||
      this.operatorControl.value === FilterOperator.blank ||
      this.operatorControl.value === FilterOperator.notBlank;
    return isNotEmpty;
  }

  showClear() {
    return this.isDirty();
  }

  isDirty() {
    return this.valueForm.dirty;
  }

  selected($event: NgbTypeaheadSelectItemEvent, control: FormControl) {
    // this.decodeFormControl.setValue($event.item);
    control.setValue($event.item);
  }

  blur(decodeInput: HTMLInputElement, control: FormControl) {
    if (!control.value) {
      decodeInput.value = '';
    }
  }

  search: OperatorFunction<string, readonly any[]> = (
    text$: Observable<string>
  ) => {
    return text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      filter((term) => term.length >= 2),
      tap(() => {
        this.autocompleteLoading = true;
        this.cd.detectChanges();
      }),
      switchMap((term) => {
        const apiClient = this.injector.get(
          this.entityName + 'ApiClient'
        ) as ApiClientInterface<BaseDto>;

        return apiClient
          .getPaged(
            10,
            0,
            {
              [this.decodeField]: 'asc',
            },
            [
              {
                field: this.decodeField,
                operator: FilterOperator.contains,
                value: [term],
                type: FilterType.text,
              },
            ]
          )
          .pipe(
            map((res) => res.items.map((x: any) => x[this.decodeField])),
            catchError(() => of([])), // empty list on error
            tap(() => {
              this.autocompleteLoading = false;
              this.cd.detectChanges();
            })
          );
      })
    );
  };
}
